import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GwbseService } from './gwbse.service';

@Injectable({
  providedIn: 'root'
})

export class GwbseResolverService implements Resolve<any>{

  constructor(private gwbseService: GwbseService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.gwbseService.getAllGwbseMaterials();
  }
}
