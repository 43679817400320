import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";

@Component({
  selector: "app-pagebody",
  templateUrl: "./pagebody.component.html",
  styleUrls: ["./pagebody.component.scss"],
})
export class PagebodyComponent implements OnInit {
  // @Input() selectedTab: string = 'overview';
  selectedTab: string = "overview";
  params: any = {};
  isLoading = true;
  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isLoading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => { });
  }

  emitSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  pageChangeHandler($event) {
    if ($event && $event.hasOwnProperty("selectedTab")) {
      this.selectedTab = $event.selectedTab;
    }
    this.params = $event.params;
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}
