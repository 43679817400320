import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit {

  selectedTab: string = 'overview';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const mybutton = document.getElementById('myBtn');
    // tslint:disable-next-line:only-arrow-functions
    window.onscroll = function() {scrollFunction(); };
    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.opacity = '1';
        mybutton.style.visibility = 'visible';
      } else {
        mybutton.style.opacity = '0';
        mybutton.style.visibility = 'hidden';
      }
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  setTab($event) {
    this.selectedTab = $event;
  }

}
