import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})

export class AboutUs implements OnInit {

  keypressed: boolean = false;
  keys: number = 0;

  teamDetails = [
    {
      name: 'Dr. Arunima K. Singh',
      github: "https://github.com/as2362",
      linkedin: 'https://www.linkedin.com/in/drarunimasingh/',
      image: "https://cmd.lab.asu.edu/sites/default/files/2022-03/arunima_singh_.jpg",
      title1: "Assistant Professor",
      title2: "Department of Physics"
    },
    {
      name: 'Tara Boland',
      github: "https://github.com/tboland1",
      linkedin: 'https://www.linkedin.com/in/tara-boland-1a4042127/',
      image: "https://cmd.lab.asu.edu/sites/default/files/2022-03/taraboland_smaller_0.png",
      title1: "4th year Ph.D. student",
      // title2: "Department of Physics, Graduate Faculty"
    },
    {
      name: 'Sydney Olson',
      github: "https://github.com/SNOlson",
      linkedin: 'https://www.linkedin.com/in/sydney-olson-210b15b4/',
      image: "assets/images/sydneyolson.jpg",
      title1: "Research Aide with a B.S. in physics",
      // title2: "His focus is on machine learning for prediction of material properties that are currently computationally expensive to calculate and will assist in the design of semiconductors. Past research has been on 2D materials and their interaction with bulk substrates. He plans on going to graduate school in 2021.",
    },
    {
      name: 'Rishabh Patil',
      github: "https://github.com/RishabhPatil",
      linkedin: 'https://www.linkedin.com/in/rishabhpatil/',
      image: "assets/images/Rishabh_patil.jpg",
      title1: "Full Stack Developer",
      // title2: "Department of Physics, Graduate Faculty"
    },
    {
      name: 'Piyush Doke',
      github: "https://github.com/piyushdoke",
      linkedin: 'https://www.linkedin.com/in/piyush-doke-243777107/',
      image: "https://avatars1.githubusercontent.com/u/25874994?s=460&v=4",
      title1: "Full Stack Developer",
      // title2: "Department of Physics, Graduate Faculty"
    },
    {
      name: 'Sughosh Jahagirdar',
      github: "https://github.com/rjsughosh",
      linkedin: 'https://www.linkedin.com/in/rjsughosh/',
      image: "https://avatars0.githubusercontent.com/u/34435043?s=400&u=b4198f2a50aac7b2d8de23af1c691056deee4b40&v=4",
      title1: "Full Stack Developer",
      // title2: "Department of Physics, Graduate Faculty"
    }
  ]

  constructor() { }

  openLink(url: string) {
    window.open(url, "_blank");
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keys += event.keyCode;
    if (this.keys == 769) {
      this.keypressed = true;
    }
    if (event.key == " ") {
      this.keypressed = false;
      this.keys = 0;
    }
  }

  ngOnInit() {
  }

}
