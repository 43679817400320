import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewComponent } from './overview/overview.component';
import { AboutUs } from './about-us/about-us.component';
import { ContactUs } from './contact-us/contact-us.component';
import { MatButtonModule, MatCardModule, MatToolbarModule, MatTabsModule, MatSelectModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainpageComponent } from './mainpage/mainpage.component';
import { PagebodyComponent } from './pagebody/pagebody.component';
import { ElementComponent } from './element/element.component';
import { DataPageComponent } from './datapage/datapage.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DetailssampleComponent } from './detailssample/detailssample.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MatExpansionModule } from '@angular/material/expansion';
import { LoadingScreenComponent } from './Shared/loading-spinner/loading-screen/loading-screen.component';
import { GwbseDataComponent } from './gwbse-data/gwbse-data.component';
import { GwbseDetailDataComponent } from './gwbse-data/gwbse-detail-data/gwbse-detail-data.component';
import { GwbseService } from './gwbse-data/gwbse.service';
import { GwbseResolverService } from './gwbse-data/gwbse-resolver.service';
import { GwBsImageResolverService } from './gwbse-data/gwbse-detail-data/gw-bs-image-resolver.service';
import { GwEpsImageResolverService } from './gwbse-data/gwbse-detail-data/gw-eps-image-resolver.service';
import { PbrDataComponent } from './pbr-data/pbr-data.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    MainpageComponent,
    PagebodyComponent,
    ElementComponent,
    DataPageComponent,
    DetailssampleComponent,
    OverviewComponent,
    AboutUs,
    ContactUs,
    LoadingScreenComponent,
    GwbseDataComponent,
    GwbseDetailDataComponent,
    PbrDataComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    TableModule,
    MultiSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    CheckboxModule,
    MatTabsModule,
    RadioButtonModule,
    MatExpansionModule,
    MatSelectModule
  ],
  exports: [
    MatExpansionModule
  ],
  providers: [GwbseService, GwbseResolverService, GwBsImageResolverService, GwEpsImageResolverService],
  bootstrap: [AppComponent]
})
export class AppModule { }
