import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  trigger,
  style,
  animate,
  transition,
  state,
  AnimationBuilder,
  AnimationPlayer,
} from "@angular/animations";
import { HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import * as JSZip from "jszip";
import * as constant from "../constants/constants";

export interface Row {
  mat_2d: string;
  substrate: string;
}

export const load = [
  trigger("load", [
    state("initial", style({ opacity: "1" })),
    state("final", style({ opacity: "0" })),
    state("reset", style({ opacity: "1" })),
    transition("initial <=> final", [animate(1000)]),
    transition("reset <=> *", [animate(100)]),
  ]),
];

@Component({
  selector: "app-datapage",
  templateUrl: "./datapage.component.html",
  styleUrls: ["./datapage.component.scss"],
  animations: [load],
  encapsulation: ViewEncapsulation.None,
})
export class DataPageComponent implements OnInit {

  selectedSurfacePlanes = [];
  surfacePlanes: SelectItem[];

  validElements = {};
  data: any = [];
  allData: any = [];
  filteredData: any = [];
  selectedElement: any = null;
  keys: any = [];
  state: any = "initial";
  overlaySearchTerm = "";

  ptColumnSelected: number = 0;
  ptColumnInformation: any = {
    0: { selectedElement: null },
    1: { selectedElement: null },
  };

  selectedElements: any = { 0: [], 1: [] };
  selectedElementsMap: any = { 0: {}, 1: {} };

  StrictSearch2D: boolean = false;
  StrictSearchSubstrate: boolean = false;

  searchBarText: string = "";
  isLoading = false;

  @Output() pageChangeEmitter: EventEmitter<any> = new EventEmitter();

  @HostListener("document:keypress", ["$event"])
  keypress(e: KeyboardEvent) {
    if (e.key.length == 1) {
      this.keys.push(e.key);
      this.overlaySearchTerm += e.key;
      if (!this.ptColumnInformation[this.ptColumnSelected].selectedElement) {
        if (this.player) {
          if (this.player.hasStarted()) {
            this.player.restart();
          } else {
            this.player.play();
          }
        } else {
          this.createPlayer();
        }
      }
    }
  }

  cols1 = [
    // { field: 'No.', header: 'No.' },
    { field: "2D", header: "2D Material" },
    { field: "Substrate", header: "Substrate" },
    { field: "SurfacePlane", header: "Surface Plane" },
    { field: "Mismatch", header: "Lattice Mismatch (%)" },
    { field: "BE", header: "Binding Energy (eV/atom)" },
    { field: "AE", header: "Adsorption Energy (eV/atom)" },
    { field: "Max Area", header: "Max Area (Å<sup>2</sup>)" },
  ];

  elementMap = {};

  // Periodic elements database with information of each element

  row1_left = [{ symbol: "H", name: "Hydrogen", number: "1", block: "s" }];
  row1_right = [{ symbol: "He", name: "Helium", number: "2", block: "s" }];

  row2_left = [
    { symbol: "Li", name: "Lithium", number: "3", block: "s" },
    { symbol: "Be", name: "Beryllium", number: "4", block: "s" },
  ];

  row2_right = [
    { symbol: "B", name: "Boron", number: "5", block: "p" },
    { symbol: "C", name: "Carbon", number: "6", block: "p" },
    { symbol: "N", name: "Nitrogen", number: "7", block: "p" },
    { symbol: "O", name: "Oxygen", number: "8", block: "p" },
    { symbol: "F", name: "Fluorine", number: "9", block: "p" },
    { symbol: "Ne", name: "Neon", number: "10", block: "p" },
  ];

  row3_left = [
    { symbol: "Na", name: "Sodium", number: "11", block: "s" },
    { symbol: "Mg", name: "Magnesium", number: "12", block: "s" },
  ];

  row3_right = [
    { symbol: "Al", name: "Aluminium", number: "13", block: "p" },
    { symbol: "Si", name: "Silicon", number: "14", block: "p" },
    { symbol: "P", name: "Phosphorus", number: "15", block: "p" },
    { symbol: "S", name: "Sulfur", number: "16", block: "p" },
    { symbol: "Cl", name: "Chlorine", number: "17", block: "p" },
    { symbol: "Ar", name: "Argon", number: "18", block: "p" },
  ];

  row4 = [
    { symbol: "K", name: "Potassium", number: "19", block: "s" },
    { symbol: "Ca", name: "Calcium", number: "20", block: "s" },
    { symbol: "Sc", name: "Scandium", number: "21", block: "d" },
    { symbol: "Ti", name: "Titanium", number: "22", block: "d" },
    { symbol: "V", name: "Vanadium", number: "23", block: "d" },
    { symbol: "Cr", name: "Chromium", number: "24", block: "d" },
    { symbol: "Mn", name: "Manganese", number: "25", block: "d" },
    { symbol: "Fe", name: "Iron", number: "26", block: "d" },
    { symbol: "Co", name: "Cobalt", number: "27", block: "d" },
    { symbol: "Ni", name: "Nickel", number: "28", block: "d" },
    { symbol: "Cu", name: "Copper", number: "29", block: "d" },
    { symbol: "Zn", name: "Zinc", number: "30", block: "d" },
    { symbol: "Ga", name: "Gallium", number: "31", block: "p" },
    { symbol: "Ge", name: "Germanium", number: "32", block: "p" },
    { symbol: "As", name: "Arsenic", number: "33", block: "p" },
    { symbol: "Se", name: "Selenium", number: "34", block: "p" },
    { symbol: "Br", name: "Bromine", number: "35", block: "p" },
    { symbol: "Kr", name: "Krypton", number: "36", block: "p" },
  ];

  row5 = [
    { symbol: "Rb", name: "Rubidium", number: "37", block: "s" },
    { symbol: "Sr", name: "Strontium", number: "38", block: "s" },
    { symbol: "Y", name: "Yttrium", number: "39", block: "d" },
    { symbol: "Zr", name: "Zirconium", number: "40", block: "d" },
    { symbol: "Nb", name: "Niobium", number: "41", block: "d" },
    { symbol: "Mo", name: "Molybdenum", number: "42", block: "d" },
    { symbol: "Tc", name: "Technetium", number: "43", block: "d" },
    { symbol: "Ru", name: "Ruthenium", number: "44", block: "d" },
    { symbol: "Rh", name: "Rhodium", number: "45", block: "d" },
    { symbol: "Pd", name: "Palladium", number: "46", block: "d" },
    { symbol: "Ag", name: "Silver", number: "47", block: "d" },
    { symbol: "Cd", name: "Cadmium", number: "48", block: "d" },
    { symbol: "In", name: "Indium", number: "49", block: "p" },
    { symbol: "Sn", name: "Tin", number: "50", block: "p" },
    { symbol: "Sb", name: "Antimony", number: "51", block: "p" },
    { symbol: "Te", name: "Tellurium", number: "52", block: "p" },
    { symbol: "I", name: "Iodine", number: "53", block: "p" },
    { symbol: "Xe", name: "Xenon", number: "54", block: "p" },
  ];

  row6 = [
    { symbol: "Cs", name: "Caesium", number: "55", block: "s" },
    { symbol: "Ba", name: "Barium", number: "56", block: "s" },
    { symbol: "-", name: "-", number: "-", block: "d" },
    { symbol: "Hf", name: "Hafnium", number: "72", block: "d" },
    { symbol: "Ta", name: "Tantalum", number: "73", block: "d" },
    { symbol: "W", name: "Tungsten", number: "74", block: "d" },
    { symbol: "Re", name: "Rhenium", number: "75", block: "d" },
    { symbol: "Os", name: "Osmium", number: "76", block: "d" },
    { symbol: "Ir", name: "Iridium", number: "77", block: "d" },
    { symbol: "Pt", name: "Platinum", number: "78", block: "d" },
    { symbol: "Au", name: "Gold", number: "79", block: "d" },
    { symbol: "Hg", name: "Mercury", number: "80", block: "d" },
    { symbol: "Tl", name: "Thallium", number: "81", block: "p" },
    { symbol: "Pb", name: "Lead", number: "82", block: "p" },
    { symbol: "Bi", name: "Bismuth", number: "83", block: "p" },
    { symbol: "Po", name: "Polonium", number: "84", block: "p" },
    { symbol: "At", name: "Astatine", number: "85", block: "p" },
    { symbol: "Rn", name: "Radon", number: "86", block: "p" },
  ];

  row7 = [
    { symbol: "Fr", name: "Francium", number: "87", block: "s" },
    { symbol: "Ra", name: "Radium", number: "88", block: "s" },
    { symbol: "-", name: "-", number: "-", block: "d" },
    { symbol: "Rf", name: "Rutherfordium", number: "104", block: "d" },
    { symbol: "Db", name: "Dubnium", number: "105", block: "d" },
    { symbol: "Sg", name: "Seaborgium", number: "106", block: "d" },
    { symbol: "Bh", name: "Bohrium", number: "107", block: "d" },
    { symbol: "Hs", name: "Hassium", number: "108", block: "d" },
    { symbol: "Mt", name: "Meitnerium", number: "109", block: "d" },
    { symbol: "Ds", name: "Darmstadtium", number: "110", block: "d" },
    { symbol: "Rg", name: "Roentgenium", number: "111", block: "d" },
    { symbol: "Cn", name: "Copernicium", number: "112", block: "d" },
    { symbol: "Nh", name: "Nihonium", number: "113", block: "p" },
    { symbol: "Fl", name: "Flerovium", number: "114", block: "p" },
    { symbol: "Mc", name: "Moscovium", number: "115", block: "p" },
    { symbol: "Lv", name: "Livermorium", number: "116", block: "p" },
    { symbol: "Ts", name: "Tennessine", number: "117", block: "p" },
    { symbol: "Og", name: "Oganesson", number: "118", block: "p" },
  ];

  lanthanides = [
    { symbol: "La", name: "Lanthanum", number: "57", block: "f" },
    { symbol: "Ce", name: "Cerium", number: "58", block: "f" },
    { symbol: "Pr", name: "Praseodymium", number: "59", block: "f" },
    { symbol: "Nd", name: "Neodymium", number: "60", block: "f" },
    { symbol: "Pm", name: "Promethium", number: "61", block: "f" },
    { symbol: "Sm", name: "Samarium", number: "62", block: "f" },
    { symbol: "Eu", name: "Europium", number: "63", block: "f" },
    { symbol: "Gd", name: "Gadolinium", number: "64", block: "f" },
    { symbol: "Tb", name: "Terbium", number: "65", block: "f" },
    { symbol: "Dy", name: "Dysprosium", number: "66", block: "f" },
    { symbol: "Ho", name: "Holmium", number: "67", block: "f" },
    { symbol: "Er", name: "Erbium", number: "68", block: "f" },
    { symbol: "Tm", name: "Thulium", number: "69", block: "f" },
    { symbol: "Yb", name: "Ytterbium", number: "70", block: "f" },
    { symbol: "Lu", name: "Lutetium", number: "71", block: "f" },
  ];

  actinides = [
    { symbol: "Ac", name: "Actinium", number: "89", block: "f" },
    { symbol: "Th", name: "Thorium", number: "90", block: "f" },
    { symbol: "Pa", name: "Protactinium", number: "91", block: "f" },
    { symbol: "U", name: "Uranium", number: "92", block: "f" },
    { symbol: "Np", name: "Neptunium", number: "93", block: "f" },
    { symbol: "Pu", name: "Plutonium", number: "94", block: "f" },
    { symbol: "Am", name: "Americium", number: "95", block: "f" },
    { symbol: "Cm", name: "Curium", number: "96", block: "f" },
    { symbol: "Bk", name: "Berkelium", number: "97", block: "f" },
    { symbol: "Cf", name: "Californium", number: "98", block: "f" },
    { symbol: "Es", name: "Einsteinium", number: "99", block: "f" },
    { symbol: "Fm", name: "Fermium", number: "100", block: "f" },
    { symbol: "Md", name: "Mendelevium", number: "101", block: "f" },
    { symbol: "No", name: "Nobelium", number: "102", block: "f" },
    { symbol: "Lr", name: "Lawrencium", number: "103", block: "f" },
  ];

  twoDElementsList = ["Zn", "Te", "Mo", "Nb", "O2", "Fe", "As", "Se", "Br", "Cr", "S", "Bi",
    "Cl", "Hf", "I", "Sb"];

  substrateElementsList = ["Sr", "Ni", "Mn", "Hf", "Ir", "Y", "Pd", "Rh", "Al", "Ti", "Cu", "Au", "V",
    "Zr", "Nd", "W", "Ag", "Sc", "Ta", "Te", "Re", "Pb"];

  params = {};
  @ViewChild("searchOverlay", { static: false }) elementRef: ElementRef;
  private player: AnimationPlayer;

  constructor(
    private httpClient: HttpClient,
    private animationBuilder: AnimationBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.buildElementMap();
    this.enable2DElements();
  }

  getSurfacePlaneData() {
    let planes = new Set();
    for (const i of this.data) {
      planes.add(i["SurfacePlane"]);
    }
    this.surfacePlanes = [];
    let multiselectArr = [];
    for (const i of planes) {
      multiselectArr.push({
        label: i,
        value: i,
      });
      this.surfacePlanes = [...multiselectArr];
    }
  }

  filterData() {
    this.filteredData = this.data.filter((ele) => {
      if (this.selectedSurfacePlanes.length) {
        return this.selectedSurfacePlanes.includes(ele["SurfacePlane"]);
      }
      return true;
    });
  }

  onSurfacePlaneChange(event) {
    this.selectedSurfacePlanes = event.value;
    this.filterData();
  }

  buildElementMap() {
    const e_lists = [
      this.row1_left,
      this.row1_right,
      this.row2_left,
      this.row2_right,
      this.row3_left,
      this.row3_right,
      this.row4,
      this.row5,
      this.row6,
      this.row6,
      this.row7,
      this.actinides,
      this.lanthanides,
    ];
    for (const l of e_lists) {
      for (const ele of l) {
        this.elementMap[ele.name.toLowerCase()] = ele;
        this.elementMap[ele.symbol.toLowerCase()] = ele;
      }
    }
  }

  selectFromSearchOverlay() {
    if (this.elementMap[this.overlaySearchTerm.toLowerCase()]) {
      this.selectElement(this.elementMap[this.overlaySearchTerm.toLowerCase()]);
    }
  }

  createPlayer() {
    if (this.player) {
      this.overlaySearchTerm = "";
      this.player.destroy();
    }

    let animationFactory;

    animationFactory = this.animationBuilder.build([
      style({ opacity: 1 }),
      animate(1000, style({ opacity: 0 })),
    ]);

    this.player = animationFactory.create(this.elementRef.nativeElement);
    this.player.onDone(() => {
      this.selectFromSearchOverlay();
      this.keys = [];
      this.createPlayer();
      this.overlaySearchTerm = "";
    });
  }

  getKeys() {
    return this.keys.join("");
  }

  setSearchBarText(empty?) {
    if (empty) {
      this.searchBarText = "";
      return;
    }
    var text2d = "(";
    text2d += this.selectedElements[0].join("-");
    if (text2d.length !== 1 && !this.StrictSearch2D) {
      text2d += "-";
    }
    text2d += ")";

    var substrate = "(";
    substrate += this.selectedElements[1].join("-");
    if (substrate.length !== 1 && !this.StrictSearch2D) {
      substrate += "-";
    }
    substrate += ")";

    this.searchBarText = text2d + " " + substrate;
  }


  radioChanged(event) {
    if (event) {
      if (this.ptColumnSelected == 0) {
        this.enable2DElements();
      }
      else if (this.ptColumnSelected == 1) {
        this.enableSubstrateElements();
      }
    }
  }


  selectElement(event) {
    if (!event.symbol || event.symbol === "-") {
      return;
    }
    if (this.selectedElementsMap[this.ptColumnSelected][event.symbol]) {
      this.selectedElementsMap[this.ptColumnSelected][event.symbol] = false;
      this.selectedElements[this.ptColumnSelected] = this.selectedElements[
        this.ptColumnSelected
      ].filter((ele) => {
        return ele !== event.symbol;
      });
    } else {
      this.selectedElementsMap[this.ptColumnSelected][event.symbol] = true;
      this.selectedElements[this.ptColumnSelected].push(event.symbol);
    }
    this.setSearchBarText();
  }

  clearSelection() {
    this.selectedElementsMap[0] = {};
    this.selectedElementsMap[1] = {};
    this.selectedElements[0] = [];
    this.selectedElements[1] = [];
    this.setSearchBarText(true);
  }

  selectPtColumn(index) {
    this.ptColumnSelected = index;
  }

  handleEachArr(arr) { }

  enable2DElements() {
    for (let elem of this.substrateElementsList)
      this.validElements[elem] = false;

    for (let elem of this.twoDElementsList)
      this.validElements[elem] = true;

    this.row1_left = this.row1_left.map((x) => this.checkElement(x, this.validElements));
    this.row1_right = this.row1_right.map((x) => this.checkElement(x, this.validElements));
    this.row2_left = this.row2_left.map((x) => this.checkElement(x, this.validElements));
    this.row2_right = this.row2_right.map((x) => this.checkElement(x, this.validElements));
    this.row3_left = this.row3_left.map((x) => this.checkElement(x, this.validElements));
    this.row3_right = this.row3_right.map((x) => this.checkElement(x, this.validElements));
    this.row4 = this.row4.map((x) => this.checkElement(x, this.validElements));
    this.row5 = this.row5.map((x) => this.checkElement(x, this.validElements));
    this.row6 = this.row6.map((x) => this.checkElement(x, this.validElements));
    this.row7 = this.row7.map((x) => this.checkElement(x, this.validElements));
    this.lanthanides = this.lanthanides.map((x) => this.checkElement(x, this.validElements));
    this.actinides = this.actinides.map((x) => this.checkElement(x, this.validElements));
  }

  enableSubstrateElements() {
    for (let elem of this.twoDElementsList)
      this.validElements[elem] = false;

    for (let elem of this.substrateElementsList)
      this.validElements[elem] = true;

    this.row1_left = this.row1_left.map((x) => this.checkElement(x, this.validElements));
    this.row1_right = this.row1_right.map((x) => this.checkElement(x, this.validElements));
    this.row2_left = this.row2_left.map((x) => this.checkElement(x, this.validElements));
    this.row2_right = this.row2_right.map((x) => this.checkElement(x, this.validElements));
    this.row3_left = this.row3_left.map((x) => this.checkElement(x, this.validElements));
    this.row3_right = this.row3_right.map((x) => this.checkElement(x, this.validElements));
    this.row4 = this.row4.map((x) => this.checkElement(x, this.validElements));
    this.row5 = this.row5.map((x) => this.checkElement(x, this.validElements));
    this.row6 = this.row6.map((x) => this.checkElement(x, this.validElements));
    this.row7 = this.row7.map((x) => this.checkElement(x, this.validElements));
    this.lanthanides = this.lanthanides.map((x) => this.checkElement(x, this.validElements));
    this.actinides = this.actinides.map((x) => this.checkElement(x, this.validElements));
  }

  checkElement(element, validElements) {
    if (validElements[element.symbol]) {
      return {
        ...element,
        valid: true,
      };
    }
    return {
      ...element,
      valid: false,
    };
  }

  showAllMaterial() {
    this.isLoading = true;
    this.clearSelection();
    this.getAllMaterials();
    document.querySelector('.table-cover').classList.remove('hide');
    this.isLoading = false;
  }

  getAllMaterials() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    const body = {
      strictSearch2D: this.StrictSearch2D,
      strictSearchSubstrate: this.StrictSearchSubstrate,
      "2D": this.selectedElements[0],
      Substrate: this.selectedElements[1],
    };

    this.httpClient
      .post(
        constant.API_STRING + "searchList",
        body,
        httpOptions
      )
      .subscribe(
        (data) => {
          this.data = data;
          this.filteredData = data;
          this.getSurfacePlaneData();
          this.filterData();
        },
        (error) => {
          console.log(error);
        }
      );
  }


  downloadAllMaterial() {
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.httpClient
      .get(
        constant.API_STRING + "downloadAll",
        httpOptions
        )
      .subscribe(
        (data) => {
          this.allData = data;
          this.zipAll();
        },
        (error) => { }
      );



  }

  zipAll() {
    let count = 0;
    const zip = new JSZip();

    for (const eachElement of this.allData) {
      const fileName = eachElement['filename'] + '.cif';

      let fileData: string = '2D_on_Substrate' + '\n' + '---------------\n' + eachElement['2D_on_Substrate'] + '\n\n';
      fileData += '2D' + '\n' + '--\n' + eachElement['2D'] + '\n\n';
      fileData += 'Substrate' + '\n' + '---------\n' + eachElement['Substrate'];

      zip.file(fileName, fileData);
      count++;
      if (count === this.allData.length) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          this.isLoading = false;
          const objectUrl: string = URL.createObjectURL(content);
          const link: any = document.createElement('a');

          link.download = 'all-material.zip';
          link.href = objectUrl;

          link.click();
        });
      }
    }
  }


  search() {
    if (Array.isArray(this.selectedElements[0]) && this.selectedElements[0].length == 0
      && Array.isArray(this.selectedElements[1]) && this.selectedElements[1].length == 0) {
      alert("Please input either 2D or Subtrate material");
    }
    else {
      // this.isLoading = true;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "my-auth-token",
        }),
      };

      const body = {
        strictSearch2D: this.StrictSearch2D,
        strictSearchSubstrate: this.StrictSearchSubstrate,
        "2D": this.selectedElements[0],
        Substrate: this.selectedElements[1],
      };

      this.httpClient
        .post(
          constant.API_STRING + "searchList",
          body,
          httpOptions
        )
        .subscribe(
          (data) => {
            this.data = data;
            this.filteredData = data;
            this.getSurfacePlaneData();
            this.filterData();
            document.querySelector('.table-cover').classList.remove('hide');
          },
          (error) => { }
        );
    }
  }

  displayNumber(data) {
    if (typeof data === "number") {
      return Number(data.toFixed(3));
    } else {
      return data;
    }
  }

  openDetails(itemId) {
    this.router.navigate(["detail", itemId]);
  }
}
