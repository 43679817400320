import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DataPageComponent } from "./datapage/datapage.component";
import { OverviewComponent } from "./overview/overview.component";
import { AboutUs } from "./about-us/about-us.component";
import { ContactUs } from "./contact-us/contact-us.component";
import { MainpageComponent } from "./mainpage/mainpage.component";
import { DetailssampleComponent } from "./detailssample/detailssample.component";
import { GwbseDataComponent } from "./gwbse-data/gwbse-data.component";
import { GwbseDetailDataComponent } from "./gwbse-data/gwbse-detail-data/gwbse-detail-data.component";
import { GwbseResolverService } from "./gwbse-data/gwbse-resolver.service";
import { GwBsImageResolverService } from "./gwbse-data/gwbse-detail-data/gw-bs-image-resolver.service";
import { GwEpsImageResolverService } from "./gwbse-data/gwbse-detail-data/gw-eps-image-resolver.service";
import { PbrDataComponent } from "./pbr-data/pbr-data.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

const routes: Routes = [
  {
    path: "",
    component: MainpageComponent,
    children: [
      {
        path: "",
        component: OverviewComponent,
      },
      {
        path: "overview",
        component: OverviewComponent,
      },
      {
        path: "data",
        component: DataPageComponent,
      },
      {
        path: "gwbse-data",
        component: GwbseDataComponent,
        resolve: { gwbseData: GwbseResolverService },
      },
      {
        path: "gwbse-data/:mid",
        component: GwbseDetailDataComponent,
        resolve: {
          epsDataStatus: GwEpsImageResolverService,
          bsDataStatus: GwBsImageResolverService,
        },
      },
      {
        path: "pbr-data",
        component: PbrDataComponent,
      },
      // {
      //   path: 'about',
      //   component: AboutUs,
      // },
      {
        path: "contact",
        component: ContactUs,
      },
      {
        path: "detail/:id",
        component: DetailssampleComponent,
      },
      {
        path: "**",
        component: PageNotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
