import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as constant from '../../constants/constants';
import { GwbseService } from '../gwbse.service';

@Component({
  selector: 'app-gwbse-detail-data',
  templateUrl: './gwbse-detail-data.component.html',
  styleUrls: ['./gwbse-detail-data.component.scss']
})
export class GwbseDetailDataComponent implements OnInit, OnDestroy {

  paramsSubscription: Subscription;
  materialId: any;
  imageUrl: any;
  bseImageStatus: any;
  epsImageStatus: any;
  bsUri: any;
  epsUri: any;
  comingSoonBS = false;
  comingSoonEPS = false;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private httpClient: HttpClient,
              private gwbseService: GwbseService) {
    this.paramsSubscription = this.route.params
      .subscribe(
        (params: Params) => {
          this.materialId = params.mid;
        }
      );
  }

  ngOnInit() {
    this.route.data
      .subscribe(
        (data: Data) => {
          this.bseImageStatus = data.bsDataStatus;
          console.log(data);
          if (this.bseImageStatus.status !== 'Success') {
            this.comingSoonBS = true;
          } else {
            const imageData = this.bseImageStatus.data;
            const imageD = imageData.substring(2, imageData.length - 1);
            this.bsUri = imageD;
          }

          this.epsImageStatus = data.epsDataStatus;
          if (this.epsImageStatus.status !== 'Success') {
            this.comingSoonEPS = true;
          } else {
            const imageData = this.epsImageStatus.data;
            const imageD = imageData.substring(2, imageData.length - 1);
            this.epsUri = imageD;
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
