import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as ChemDoodle from "src/assets/js/ChemDoodleWeb.js";
import * as constant from "../constants/constants";
declare var ChemDoodle: any;

export interface PeriodicElement {
  name: string;
  key: string;
  unit?: string;
}

// Temporary DATA, will be replaced by data from DATABSE
const heterostructure: PeriodicElement[] = [
  { name: "Max Mismatch", key: "tags.max_mismatch", unit: "%" },
  { name: "Max Area", key: "tags.max_area", unit: "Å<sup>2</sup>" },
  {
    name: "Adsorption Energy",
    key: "Adsorption_Energy.E_ads",
    unit: "eV/atom",
  },
  { name: "Binding Energy", key: "Binding_Energy.E_bind", unit: "eV/atom" },
];

const twoD_Material_Information: PeriodicElement[] = [
  { name: "Ref DB ID", key: "tags.ref_db_id" },
  { name: "Lattice Parameter: a", key: "final_structure.lattice.a", unit: "Å" },
  { name: "Lattice Parameter: b", key: "final_structure.lattice.b", unit: "Å" },
  { name: "Lattice Parameter: c", key: "final_structure.lattice.c", unit: "Å" },
  { name: "Film Spacegroup: number", key: "tags.spacegroup.number" },
  { name: "Film Spacegroup: hall number", key: "tags.spacegroup.hall_number" },
  {
    name: "Film Spacegroup: international",
    key: "tags.spacegroup.international",
  },
  { name: "Film Spacegroup: hall", key: "tags.spacegroup.hall" },
  { name: "Film Spacegroup: pointgroup", key: "tags.spacegroup.pointgroup" },
];

const Substrate_Slab: PeriodicElement[] = [
  { name: "Ref DB ID", key: "tags.ref_db_id", unit: "" },
  { name: "Lattice Parameter: a", key: "final_structure.lattice.a", unit: "Å" },
  { name: "Lattice Parameter: b", key: "final_structure.lattice.b", unit: "Å" },
  { name: "Lattice Parameter: c", key: "final_structure.lattice.c", unit: "Å" },
];

@Component({
  selector: "app-detailssample",
  templateUrl: "./detailssample.component.html",
  styleUrls: ["./detailssample.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailssampleComponent implements OnInit, AfterViewInit {
  @ViewChild("canvasH", { static: false }) canvasH: ElementRef;
  @ViewChild("canvas2D", { static: false }) canvas2D: ElementRef;
  @ViewChild("canvasSubstrate", { static: false }) canvasSubstrate: ElementRef;
  @ViewChild("newd", { static: false }) newd: ElementRef;

  @ViewChild("download1", { static: false }) download1: ElementRef;
  @ViewChild("download2", { static: false }) download2: ElementRef;
  @ViewChild("download3", { static: false }) download3: ElementRef;

  @Input() id: string;

  selectedTab: string = "overview";

  details_title = "";
  details_subtitle = "";

  emitSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  data_2D: any;
  data_heterostructure: any;
  data_substrate: any;

  dataSource = heterostructure;

  dataSource_second = twoD_Material_Information;

  dataSource_third = Substrate_Slab;

  wf_name: string = "";

  viewLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.has("id")) {
      this.wf_name = this.route.snapshot.paramMap.get("id");
    } else {
      this.wf_name = this.id;
    }
    this.setPageTitles();

    // this.get_2D(this.wf_name);
    this.get_2D_on_Substrate(this.wf_name);
    // this.get_substrate(this.wf_name);
  }

  setPageTitles() {
    let split_arr = this.wf_name.split(":");
    let midText = split_arr[2].replace(/-/g, " ");
    let numb = split_arr[1].match(/\d+/g);
    let config_numb = split_arr[3].match(/\d+/g);
    this.details_title = `2D ${midText} (${numb[0]}) Substrate `;
    this.details_subtitle = `Heterostructure Configuration #${parseInt(config_numb[0]) + 1
      }`;
  }

  ngAfterViewInit() {
    this.viewLoaded = true;
  }

  showCanvas(parent, id, data) {
    if (this.viewLoaded) {
      var cif = ChemDoodle.readCIF(data["cif"], 2, 2, 1);
      // , window.innerWidth, window.innerHeight

      var crystalTransformer = new ChemDoodle.TransformCanvas3D(
        id,
        parent.nativeElement.offsetWidth,
        parent.nativeElement.offsetHeight,
        false
      );
      crystalTransformer.specs.set3DRepresentation("Ball and Stick");
      crystalTransformer.specs.backgroundColor = "#eeeeee";
      crystalTransformer.specs.projectionPerspective_3D = true;
      crystalTransformer.specs.atoms_displayLabels_3D = true;

      crystalTransformer.loadContent([cif.molecule], [cif.unitCell]);
      // crystalTransformer.rotationMatrix = [1,0,0,0,0,0.70710678118,-0.70710678118,0,0,0.70710678118,0.70710678118,0,0,0,0,1]
      crystalTransformer.rotationMatrix = [
        0.70710678118,
        0,
        0.70710678118,
        0,
        0,
        1,
        0,
        0,
        -0.70710678118,
        0,
        0.70710678118,
        0,
        0,
        0,
        0,
        1,
      ];
      crystalTransformer.repaint();
    } else {
      setTimeout(() => this.showCanvas(parent, id, data), 0);
    }
  }

  getNestedKey(obj, key) {
    var keys = key.split(".");
    let ret = obj;
    for (const i of keys) {
      if (i && ret && ret[i]) {
        ret = ret[i];
      }
    }
    return ret;
  }

  get_2D(compound) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.httpClient
      .get(
        constant.API_STRING + "search/2D?compound=" + compound,
        httpOptions
      )
      .subscribe(
        (data) => {
          // debugger
          this.data_2D = data;
          this.showCanvas(this.canvas2D, "canvas2D", this.data_2D);
          this.download1.nativeElement.setAttribute(
            "href",
            `data:${"text:plain"};charset=utf-8,${encodeURIComponent(
              data["cif"]
            )}`
          );
          this.download1.nativeElement.setAttribute("download", "2D.cif");
        },
        (error) => { }
      );
  }

  get_substrate(compound) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.httpClient
      .get(
        constant.API_STRING + "search/Substrate?compound=" + compound,
        httpOptions
      )
      .subscribe(
        (data) => {
          this.data_substrate = data;
          this.showCanvas(
            this.canvasSubstrate,
            "canvasSubstrate",
            this.data_substrate
          );
          this.download2.nativeElement.setAttribute(
            "href",
            `data:${"text:plain"};charset=utf-8,${encodeURIComponent(
              data["cif"]
            )}`
          );
          this.download2.nativeElement.setAttribute(
            "download",
            "Substrate.cif"
          );
        },
        (error) => { }
      );
  }

  get_2D_on_Substrate(wf_name) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.httpClient.get(constant.API_STRING + "search/2D_on_Substrate?wf_name=" + wf_name,
      httpOptions
    )
      .subscribe(
        (data) => {
          this.data_heterostructure = data;
          this.get_2D(data["tags"]["film_composition"]);
          this.get_substrate(data["tags"]["substrate_composition"]);
          this.showCanvas(this.canvasH, "canvasH", this.data_heterostructure);
          this.download3.nativeElement.setAttribute(
            "href",
            `data:${"text:plain"};charset=utf-8,${encodeURIComponent(
              data["cif"]
            )}`
          );
          this.download3.nativeElement.setAttribute(
            "download",
            "Heterostructure.cif"
          );
        },
        (error) => { }
      );
  }

  // Function to limit the floats upto three decimal points
  displayData(data) {
    // if the data if of type number, limit the decimal points upto three
    if (typeof data === "number") {
      return Number(data.toFixed(3));
    }
    // else leave the data as it is
    else {
      return data;
    }
  }

  // Function handling the decimal numbers
  displayFieldWithUnits(data, field) {
    let txt = this.displayData(this.getNestedKey(data, field.key));
    // if the field has units, then append it to the data
    if (field.unit) {
      txt += " " + field.unit;
    }
    return txt;
  }
}
