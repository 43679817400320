import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {getSortHeaderNotContainedWithinSortError} from '@angular/material/sort/typings/sort-errors';

@Component({
  selector: 'app-gwbse-data',
  templateUrl: './gwbse-data.component.html',
  styleUrls: ['./gwbse-data.component.scss']
})
export class GwbseDataComponent implements OnInit {
  materialsList: any;
  downloadFileName = '';
  totalData: any;
  constructor(private route: ActivatedRoute,
              private router: Router) { }

  cols: any[];
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  ngOnInit() {
    this.route.data
      .subscribe(
        (data: Data) => {
          this.materialsList = data['gwbseData'];
          this.totalData = this.materialsList.length;
        }
      );
    this.cols = [
          { field: 'material_id', header: 'Material Id' },
          { field: 'formula_pretty', header: 'Formula' },
          { field: 'dft_direct_gap', header: 'KS Direct Gap (eV)' },
          { field: 'dft_indirect_gap', header: 'DFT Indirect Gap (eV)' },
          { field: 'gw_direct_gap', header: 'QP Direct Gap (eV)' },
          { field: 'gw_indirect_gap', header: 'GW Indirect Gap (eV)' },
          { field: 'optical_gap', header: 'Optical Gap (eV)' },
          { field: 'exciton_binding_energy', header: 'Exciton Binding Energy (eV)' },
          { field: 'h_mass', header: 'Hole Effective Masses (MIN, MAX, AVG) (m<sub>e</sub>)' },
          { field: 'e_mass', header: 'Electron Effective Masses (MIN, MAX, AVG) (m<sub>e</sub>)' },
          { field: 'epsilon_1', header: 'ε<sub>xx</sub>' },
          { field: 'epsilon_2', header: 'ε<sub>yy</sub>' },
          { field: 'epsilon_3', header: 'ε<sub>zz</sub>' },
          { field: 'structure', header: 'Results' },
      ];
  }
  dwnldGwbseData(): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.materialsList);
    const myworkbook: XLSX.WorkBook = { Sheets: { data: myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'excelFileName');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + this.EXCEL_EXTENSION);
  }


  openDetails(itemId: String) {
    this.router.navigate(["gwbse-data", itemId]);
  }
}
