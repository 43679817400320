import { Output, EventEmitter } from "@angular/core";
import { Component, OnChanges, Input } from "@angular/core";

@Component({
  selector: "app-element",
  templateUrl: "./element.component.html",
  styleUrls: ["./element.component.scss"],
})
export class ElementComponent implements OnChanges {
  @Input() elementData: any = null;
  @Input() elementSymbol: string = "-";
  @Input() elementNumber: string = "-";
  @Input() elementName: string = "-";
  @Input() validObj;
  @Input() validElements;
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.elementData.symbol == "O") {
    }
  }

  emit() {
    const obj = this.elementData;
    this.clickEmitter.emit(obj);
  }

  ngOnChanges() {
    if (this.elementData.symbol == "O") {
    }
  }
}
