import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as constant from '../constants/constants';

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUs implements OnInit {
  name: string;
  email: string;
  message: string;
  isLoading = false;
  status: any = {};
  @ViewChild('f', { static: false }) contactUsForm: NgForm;
  constructor(public httpClient: HttpClient) { }

  ngOnInit() { }

  processForm() {
    this.isLoading = true;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    let user = {
      name: this.contactUsForm.value.userData.fullname,
      emailId: this.contactUsForm.value.userData.email,
      emailBody: this.contactUsForm.value.userData.message,
    };

    this.httpClient
      .post(
        constant.API_STRING + "sendEmail",
        user,
        httpOptions
      ).subscribe(
        (status) => {
          this.status = status;
          this.checkStatus();
          this.isLoading = false;
        },
        (error) => {
          window.alert('Sorry for the inconvenience! Admin has been notified with this error');
          this.isLoading = false;
        }
      );
  }

  checkStatus() {
    if (this.status['status'] === 'Success') {
      window.alert('Feedback submitted successfully');
    }
    else {
      window.alert('Sorry for the inconvenience! Admin has been notified with this error');
    }
  }
}
