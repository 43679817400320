import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as constant from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class GwbseService {

  constructor(private httpClient: HttpClient) { }
  materialsList: any;
  bseImageData: any;
  epsImageData: any;

  async getAllGwbseMaterials(){
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.materialsList = await this.httpClient
      .get(
        constant.API_STRING + "getGwbseMaterials",
        httpOptions
      ).toPromise();

      return this.materialsList;
  }

  async getBseImageData(mid: String){
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.bseImageData = await this.httpClient
      .get(
        constant.API_STRING + "getBS/" + mid,
        httpOptions
      ).toPromise();

      return this.bseImageData;
  }


  async getEpsImageData(mid: String){
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.epsImageData = await this.httpClient
      .get(
        constant.API_STRING + "getEPS/" + mid,
        httpOptions
      ).toPromise();

      return this.epsImageData;
  }

}
