import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import * as constant from "../constants/constants";
import { MatSelectModule } from "@angular/material/select";
import { filter } from "jszip";

@Component({
  selector: "app-pbr-data",
  templateUrl: "./pbr-data.component.html",
  styleUrls: ["./pbr-data.component.scss"],
})
export class PbrDataComponent implements OnInit {
  name: string;
  email: string;
  message: string;
  isLoading = false;
  isResult = false;
  status: any = {};
  selectedpHValue = 7;
  selectedConcentrationValue = 0.000001;
  filter = "True";
  result = "";
  fig = "";
  @ViewChild("f", { static: false }) pbrForm: NgForm;
  constructor(public httpClient: HttpClient) {}

  filterValues = [{ id: "True" }, { id: "False" }];

  phValues = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
  ];

  concentationValue = [
    { id: 0.1 },
    { id: 0.01 },
    { id: 0.001 },
    { id: 0.0001 },
    { id: 0.00001 },
    { id: 0.000001 },
    { id: 0.0000001 },
    { id: 0.00000001 },
    { id: 0.000000001 },
    { id: 0.0000000001 },
  ];

  ngOnInit() {}

  processForm() {
    const userData = {
      mid: this.pbrForm.value.userData.mid,
      vValue: this.pbrForm.value.userData.vValue,
      concentrationValue: this.selectedConcentrationValue,
      filterValue: this.filter,
      ph: this.selectedpHValue,
    };

    console.log(userData);
    this.isLoading = true;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };

    this.httpClient
      .post(constant.API_STRING + "getPBRCalc", userData, httpOptions)
      .subscribe(
        (status) => {
          console.log(status);
          this.status = status;
          if (this.checkStatus(status)) {
            this.isResult = true;
            console.log(this.status.data);
            this.result = this.status.data;
            this.fig = this.status.fig;
            this.fig = this.fig.substring(2, this.fig.length - 1);
          } else {
            this.isResult = false;
          }
          this.isLoading = false;
        },
        (error) => {
          window.alert(
            "Network error. Admin has been notified with this error"
          );
          this.isLoading = false;
        }
      );
  }

  checkStatus(status) {
    if (this.status["status"] !== "Success") {
      window.alert("Error : " + status["error"]);
      return false;
    }

    return true;
  }
}
